
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class NewFeatureRefTableStatusIcon extends Vue {
  @Prop({ required: true }) status: string

  getStatusTooltipText (status: string) {
    switch (status) {
      case 'incompatible':
        return 'Incompatible'
      case 'alpha':
        return 'Alpha'
      case 'beta':
        return 'Beta'
      case 'released_with_exception':
        return 'Released with exceptions'
      case 'fully_released':
        return 'Fully released'
    }
  }

  getStatusIcon (status: string) {
    switch (status) {
      case 'incompatible':
        return 'block'
      case 'alpha':
        return 'filter_1'
      case 'beta':
        return 'filter_2'
      case 'released_with_exception':
        return 'filter_3'
      case 'fully_released':
        return 'check'
    }
  }
}
