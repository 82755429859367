
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class BasicSearchBar extends Vue {
  @Prop({ required: false, default: null }) search: string

  searchTimeOut: string = ''
  searchTimeOutId: any = null

  mounted () {
    if (this.search != null && this.search !== '') {
      this.searchTimeOut = this.search
    }
  }

  @Watch('searchTimeOut')
  onSearchTimeOutChange () {
    if (this.searchTimeOutId !== null) {
      clearTimeout(this.searchTimeOutId)
    }
    this.searchTimeOutId = setTimeout(() => {
      this.$emit('refresh', this.searchTimeOut)
    }, 300)
  }
}
