
import Vue from 'vue'
import { Component, Prop, VModel } from 'vue-property-decorator'
import { InstructionDsp } from '../../../types/instruction_type'
import NewFeatureReferenceModel from '@/models/Keystone_v2/NewFeatureReferenceModel'
import MemberFeatureRefsMembersModel from '@/models/Keystone_v2/MemberFeatureRefsMembersModel'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'
import { isBetaDsp } from '../../../types/instruction_type_helper'

const RETURN_MESSAGE = {
  NOWHERE: 'Nowhere',
  EVERYWHERE_FULL: 'Everywhere, the feature is fully deployed',
  ONLY_FOLLOWING: 'Only on the following seat:',
  EVERYWHERE_NO_EXCEPTION: 'Everywhere, no exception for this release',
  ON_EVERY_BUT: 'On every seat but the following ones:'
}

type ManagedDspNFR = Exclude<InstructionDsp, 'facebook' | 'meta'>
type KeyofDspStatus = `${ManagedDspNFR}Status` ;
@Component({
  components: { CopyClipboardComponent }
})
export default class NewFeatureRefsMembers extends Vue {
  @Prop({ required: true }) feature: NewFeatureReferenceModel
  @VModel()
  dialog: boolean
  members: Array<MemberFeatureRefsMembersModel> = []
  membersByDsp: Record<InstructionDsp, MemberFeatureRefsMembersModel[]> = {
    appnexus: [],
    dbm: [],
    youtube: [],
    mediamath: [],
    thetradedesk: [],
    beeswax: [],
    kayzen: [],
    facebook: [],
    meta: []
  }
  isError :boolean = false;
  messageError : string = '';
  loadingFeatureMembers :boolean = true

  mounted () {
    if (this.feature) {
      this.openModalMembers()
    }
  }
  async openModalMembers () {
    const response = await this.$apiCaller.getNewFeatureReferencesMembers(this.feature.id)
    if (this.$apiCaller.isResponseError(response)) {
      this.$store.commit('setErrorMessageWithResponse', response)
      this.isError = true
      this.loadingFeatureMembers = false
      this.messageError = 'Error received.'
      console.warn('Error received.')
    } else {
      if (!response.data) {
        this.isError = true
        this.messageError = 'No data received.'
        console.warn('No data received.')
      }
      this.loadingFeatureMembers = false
      this.formatMembersByModel(response.data)
      this.splitByDsp()
    }
  }

  formatMembersByModel (members: Array<MemberFeatureRefsMembersModel>) {
    for (let index of members) {
      this.members.push(new MemberFeatureRefsMembersModel(index))
    }
  }

  getStatusMessage (dsp: ManagedDspNFR) {
    if (!this.feature) {
      return ''
    }
    let key : KeyofDspStatus = `${dsp}Status`
    const dspStatus : string = this.feature[key]

    if (dspStatus === 'incompatible') {
      return RETURN_MESSAGE.NOWHERE
    } else if (dspStatus === 'fully_released') {
      return RETURN_MESSAGE.EVERYWHERE_FULL
    } else if ((dspStatus === 'alpha' || dspStatus === 'beta')) {
      if (this.membersByDsp[dsp].length === 0) {
        return RETURN_MESSAGE.NOWHERE
      } else {
        return RETURN_MESSAGE.ONLY_FOLLOWING
      }
    } else if (dspStatus === 'released_with_exception') {
      if (this.membersByDsp[dsp].length === 0) {
        return RETURN_MESSAGE.EVERYWHERE_NO_EXCEPTION
      } else {
        return RETURN_MESSAGE.ON_EVERY_BUT
      }
    }
  }
  splitByDsp () {
    for (let member of this.members) {
      if (member.dsp === 'facebook') {
        console.warn('Facebook dsp is not supported !')
        continue
      }

      if (isBetaDsp(member.dsp)) {
        console.warn('Dsp is not supported by new feature refs !')
        continue
      }
      let dsp: InstructionDsp = member.dsp

      let key: KeyofDspStatus = `${dsp}Status`
      this._checkFeatureKey(key, member, dsp)

      if (member.dsp === 'dbm') {
        let dsp: InstructionDsp = 'youtube'
        let key: KeyofDspStatus = 'youtubeStatus'
        this._checkFeatureKey(key, member, dsp)
      }
    }
  }
  _checkFeatureKey (key: KeyofDspStatus, member: MemberFeatureRefsMembersModel, dsp: ManagedDspNFR) {
    if (this.feature[key] === 'alpha' || this.feature[key] === 'beta') {
      if (member.tableType === 'NewFeatureRefMember' && this.membersByDsp[dsp]) {
        this.membersByDsp[dsp].push(member)
      }
    } else if (this.feature[key] === 'released_with_exception') {
      if (member.tableType === 'LegacyFeatureRefMember' && this.membersByDsp[dsp]) {
        this.membersByDsp[dsp].push(member)
      }
    } else {
      this.membersByDsp[dsp].push(member)
    }
  }
  displayId (dsp: ManagedDspNFR) {
    const status = this.getStatusMessage(dsp)
    return (status === RETURN_MESSAGE.ONLY_FOLLOWING ||
      status === RETURN_MESSAGE.ON_EVERY_BUT)
  }
  getExternalIds (member: MemberFeatureRefsMembersModel[]) {
    return member.map((val) => val.externalId).join(', ')
  }
  closeModalMembers () {
    this.dialog = false
  }
}

