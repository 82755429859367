import BaseKeystoneEntity from '@/models/Keystone_v2/BaseKeystoneEntity'
// eslint-disable-next-line import/no-duplicates
import { InstructionDsp } from '../../../types/instruction_type'
// eslint-disable-next-line import/no-duplicates
import { Members } from '../../../types/instruction_type'
class MemberModel extends BaseKeystoneEntity {
  id: string
  dsp: InstructionDsp
  name: string
  externalId: string
  isSet: boolean
  // eslint-disable-next-line camelcase
  constructor (data: Partial<Members> = {}) {
    super(data)
    this.id = data.id
    this.dsp = data.dsp
    this.name = data.name
    this.externalId = data.external_id
    this.isSet = data.is_set
    this.saveOriginal()
  }
}

export default MemberModel
