
import { Component, Prop, Watch } from 'vue-property-decorator'
import NewFeatureReferenceModel from '../../../../models/Keystone_v2/NewFeatureReferenceModel'
import SimpleDialog from '../../../TableComponents/Form/FormFragments/SimpleDialog.vue'
import _ from 'lodash'
import { mixins } from 'vue-class-component'
import { rulesMixin } from '../../../../mixins/rulesMixin'

export const r = new RegExp('^[a-z_0-9]+$')

@Component({
  components: {
    SimpleDialog
  }
})
export default class NewFeatureRefsManagerForm extends mixins(rulesMixin) {
  @Prop({ required: false, default: false }) newFeatureDialogOn: boolean
  @Prop({ required: false, default: true }) isEditForm: boolean
  @Prop({ required: false, default: null }) newFeatureRef: NewFeatureReferenceModel

  model: NewFeatureReferenceModel = null
  dialogOn: boolean = false
  valid: boolean = false

  dspList: Array<any> = [
    { text: 'Appnexus Status', key: 'appnexusStatus', name: 'appnexus', warning: false },
    { text: 'DBM Status', key: 'dbmStatus', name: 'dbm', warning: true },
    { text: 'YouTube Status', key: 'youtubeStatus', name: 'youtube', warning: true },
    { text: 'Mediamath Status', key: 'mediamathStatus', name: 'mediamath', warning: false },
    { text: 'TheTradeDesk Status', key: 'thetradedeskStatus', name: 'thetradedesk', warning: false },
    { text: 'Beeswax Status', key: 'beeswaxStatus', name: 'beeswax', warning: false },
    { text: 'Kayzen Status', key: 'kayzenStatus', name: 'kayzen', warning: false }
  ]

  dbmAndYoutubeStatusWarningTooltip: string = 'DBM & Youtube status should have either the same value, or at least one of them should be incompatible'
  dbmAndYoutubeWarning: boolean = false

  loadingDevNames: boolean = true
  badDevNameWarningTooltip: string = 'Dev Name is alread used. Please input another one.'
  alreadyUsedDevNames: Array<string> = []
  badDevNameWarning: boolean = false

  statusItems: Array<string> = [
    'incompatible',
    'alpha',
    'beta',
    'released_with_exception',
    'fully_released'
  ]

  statusItemsToDisplayPerDsp: { [key: string]: Array<string> } = null

  applicationLevelItems: Array<string> = [
    'member',
    'seat',
    'company',
    'holding',
    'insertion_order'
  ]

  debug (elem: any) {
    console.log('debug', elem)
  }

  mounted () {
    this.setData()
    this.getAlreadyUsedDevNames()
  }

  async getAlreadyUsedDevNames () {
    const result = await this.$apiCaller.getNewFeatureReferences(true)
    if (this.$apiCaller.isResponseError(result)) {
      const error = this.$commonUtils.getErrorInResponse(result)
      console.warn(`Error when calling checkNewFeatureReference for only_dev_names. Error ${error}`)
      this.$store.commit('setErrorMessageWithResponse', result)
    } else {
      result.data.forEach((elem: any) => this.alreadyUsedDevNames.push(elem.dev_name))
      this.loadingDevNames = false
    }
  }

  setData () {
    this.dialogOn = this.newFeatureDialogOn
    if (!this.isEditForm && this.newFeatureRef == null) {
      this.model = new NewFeatureReferenceModel()
    } else {
      this.model = _.cloneDeep(this.newFeatureRef)
    }
    this.setStatusItemsToDisplayPerDsp()
  }

  setStatusItemsToDisplayPerDsp () {
    this.statusItemsToDisplayPerDsp = {}
    for (let dsp of this.dspList) {
      this.statusItemsToDisplayPerDsp[dsp.name] = this.model[dsp.key as keyof NewFeatureReferenceModel] === 'incompatible'
        ? this.statusItems
        : this.statusItems.slice(1)
    }
  }

  // SPECIAL RULES
  newFeatureRefDevNameRule (value: string) {
    if (value == null) { return 'Cannot be empty' }
    return r.exec(value) != null ? true : 'Dev name can only contains lower case letters and underscore (_)'
  }

  saveChanges () {
    let errorCount = 0
    if (this.isDbmAndYoutubeStatusOkay === false) {
      this.showDbmAndYoutubeWarning()
      errorCount += 1
    }
    if (!this.isEditForm && this.isDevNameOkay === false) {
      this.showBadDevNameWarning()
      errorCount += 1
    }
    if (errorCount > 0) { return }
    const emitter = !this.isEditForm ? 'createNewFeatureReference' : 'updateNewFeatureReference'
    this.$emit(emitter, this.model)
  }

  reset () {
    this.model = null
    this.badDevNameWarning = false
    this.dbmAndYoutubeWarning = false
  }

  close () {
    this.reset()
    this.$emit('close')
  }

  statusItemsToDisplay (currentValue: string) {
    return currentValue !== 'incompatible' ? this.statusItems.slice(1) : this.statusItems
  }

  get isDevNameOkay (): boolean {
    if (this.model.devName == null || this.model.devName === '') { return false }
    return !(this.alreadyUsedDevNames.includes(this.model.devName))
  }

  get isDbmAndYoutubeStatusOkay (): boolean {
    return (
      this.model.dbmStatus === this.model.youtubeStatus ||
      (this.model.dbmStatus === 'incompatible' && this.model.youtubeStatus !== 'incompatible') ||
      (this.model.dbmStatus !== 'incompatible' && this.model.youtubeStatus === 'incompatible')
    )
  }

  cannotUpdateWarningTooltip (field: string): string {
    return (
      this.isEditForm
        ? `${field} cannot be updated`
        : `${field} won't be updatable after saving changes.`
    )
  }

  showDbmAndYoutubeWarning () {
    this.dbmAndYoutubeWarning = true
  }

  showBadDevNameWarning () {
    this.badDevNameWarning = true
  }

  // GETTERS
  get headerContent () {
    return this.isEditForm ? 'manage new feature reference' : 'add new feature reference'
  }
  // todo sly -> remove this when there will be other things than member as application level
  get applicationLevelDisabled () {
    return true
  }

  @Watch('newFeatureDialogOn')
  onNewFeatureReferenceEditDialog () {
    this.setData()
  }

  @Watch('model.devName')
  onDevNameChange () {
    if (this.badDevNameWarning === true && this.isDevNameOkay === true) {
      this.badDevNameWarning = false
    }
  }
  @Watch('model.dbmStatus')
  onDbmStatusChange () {
    if (this.dbmAndYoutubeWarning === true && this.isDbmAndYoutubeStatusOkay === true) {
      this.dbmAndYoutubeWarning = false
    }
  }
  @Watch('model.youtubeStatus')
  onYoutubeStatusChange () {
    if (this.dbmAndYoutubeWarning === true && this.isDbmAndYoutubeStatusOkay === true) {
      this.dbmAndYoutubeWarning = false
    }
  }
}
