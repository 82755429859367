import { MembersOfNewFeature } from '../../../types/instruction_type'
import MemberModel from '@/models/Keystone_v2/MemberModel'
class MemberFeatureRefsMembersModel extends MemberModel {
  tableType: string
  // eslint-disable-next-line camelcase
  constructor (data: Partial<MembersOfNewFeature> = {}) {
    super(data)
    this.tableType = data.table_type
    this.saveOriginal()
  }
}

export default MemberFeatureRefsMembersModel
